// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../styleguide/components/Heading/H1.res.js";
import * as ID from "../../../../libs/ID.res.js";
import * as Role from "../../../../models/Role.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as Table from "../../../../styleguide/components/Table/Table.res.js";
import * as React from "react";
import * as $$String from "rescript/lib/es6/string.js";
import * as Toggle from "../../../../styleguide/forms/Toggle/Toggle.res.js";
import * as Control from "../../../../styleguide/components/Control/Control.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Spinner from "../../../../styleguide/components/Spinner/Spinner.res.js";
import * as Api_User from "../../../../api/users/Api_User.res.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../../styleguide/icons/IconMenu.res.js";
import * as TableRow from "../../../../styleguide/components/Table/TableRow.res.js";
import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as IconClose from "../../../../styleguide/icons/IconClose.res.js";
import * as TableBody from "../../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../../styleguide/components/Table/TableCell.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconSorting from "../../../../styleguide/icons/IconSorting.res.js";
import * as Routes_User from "../../../../routes/common/Routes_User.res.js";
import * as SearchField from "../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as TableHeader from "../../../../styleguide/components/Table/TableHeader.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as UserEditForm from "./components/UserEditForm.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as TableHeaderCell from "../../../../styleguide/components/Table/TableHeaderCell.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UsersDashboardNavbar from "../common/navbar/UsersDashboardNavbar.res.js";
import * as UsersDashboardScss from "./UsersDashboard.scss";

var css = UsersDashboardScss;

var initialState_sortBy = {
  NAME: "FirstName",
  VAL: "Asc"
};

var initialState_rolesFilter = [];

var initialState_selection = ID.$$Set.make();

var initialState_entityApproveUpdates = ID.$$Map.make();

var initialState_entityApiEnableUpdates = ID.$$Map.make();

var initialState_entityRoleUpdates = ID.$$Map.make();

var initialState_entityMarketplaceLeadsUpdates = ID.$$Map.make();

var initialState_entityDailyLeadsUpdates = ID.$$Map.make();

var initialState_entitySignupDistributionUpdates = ID.$$Map.make();

var initialState = {
  status: "FetchingUsers",
  currentPage: 1,
  totalPages: 1,
  sortBy: initialState_sortBy,
  search: "",
  rolesFilter: initialState_rolesFilter,
  selection: initialState_selection,
  editUser: undefined,
  entityApproveUpdates: initialState_entityApproveUpdates,
  entityApiEnableUpdates: initialState_entityApiEnableUpdates,
  entityRoleUpdates: initialState_entityRoleUpdates,
  entityMarketplaceLeadsUpdates: initialState_entityMarketplaceLeadsUpdates,
  entityDailyLeadsUpdates: initialState_entityDailyLeadsUpdates,
  entitySignupDistributionUpdates: initialState_entitySignupDistributionUpdates,
  isEditModalOpen: false,
  users: undefined,
  totalUsers: 1,
  currentUserId: undefined
};

function UsersDashboard$default(props) {
  var container = React.useRef(null);
  var searchUsers = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchUsers" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var match = state.search;
                              var tmp = match === "" ? undefined : state.search;
                              $$Promise.wait(Api_User.dashboard(state.currentPage, tmp, state.sortBy, state.rolesFilter), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedUsersFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "UsersDashboard",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "UsersDashboard.default"
                                          }, "UsersDashboard::FetchUsers::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailUsersFetch");
                                    }));
                            })
                        };
              case "FailUsersFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedUsersFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            rolesFilter: state.rolesFilter,
                            selection: state.selection,
                            editUser: state.editUser,
                            entityApproveUpdates: state.entityApproveUpdates,
                            entityApiEnableUpdates: state.entityApiEnableUpdates,
                            entityRoleUpdates: state.entityRoleUpdates,
                            entityMarketplaceLeadsUpdates: state.entityMarketplaceLeadsUpdates,
                            entityDailyLeadsUpdates: state.entityDailyLeadsUpdates,
                            entitySignupDistributionUpdates: state.entitySignupDistributionUpdates,
                            isEditModalOpen: state.isEditModalOpen,
                            users: state.users,
                            totalUsers: state.totalUsers,
                            currentUserId: state.currentUserId
                          }
                        };
              case "CloseModal" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            rolesFilter: state.rolesFilter,
                            selection: state.selection,
                            editUser: undefined,
                            entityApproveUpdates: state.entityApproveUpdates,
                            entityApiEnableUpdates: state.entityApiEnableUpdates,
                            entityRoleUpdates: state.entityRoleUpdates,
                            entityMarketplaceLeadsUpdates: state.entityMarketplaceLeadsUpdates,
                            entityDailyLeadsUpdates: state.entityDailyLeadsUpdates,
                            entitySignupDistributionUpdates: state.entitySignupDistributionUpdates,
                            isEditModalOpen: false,
                            users: state.users,
                            totalUsers: state.totalUsers,
                            currentUserId: state.currentUserId
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "FetchUser" :
                  var id = action._0;
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api_User.fetchUserDashboard(id), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedFetchUser",
                                                    _0: x._0
                                                  });
                                      } else {
                                        return SentryLogger.error1({
                                                    rootModule: "UsersDashboard",
                                                    subModulePath: /* [] */0,
                                                    value: "default",
                                                    fullPath: "UsersDashboard.default"
                                                  }, "UsersDashboard::FetchUser::Error", [
                                                    "Error",
                                                    x._0
                                                  ]);
                                      }
                                    }));
                            })
                        };
              case "SucceedUsersFetch" :
                  var res = action._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res.users
                            },
                            currentPage: res.currentPage,
                            totalPages: res.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            rolesFilter: state.rolesFilter,
                            selection: state.selection,
                            editUser: state.editUser,
                            entityApproveUpdates: state.entityApproveUpdates,
                            entityApiEnableUpdates: state.entityApiEnableUpdates,
                            entityRoleUpdates: state.entityRoleUpdates,
                            entityMarketplaceLeadsUpdates: state.entityMarketplaceLeadsUpdates,
                            entityDailyLeadsUpdates: state.entityDailyLeadsUpdates,
                            entitySignupDistributionUpdates: state.entitySignupDistributionUpdates,
                            isEditModalOpen: state.isEditModalOpen,
                            users: res.users,
                            totalUsers: res.totalUsers,
                            currentUserId: Caml_option.some(res.currentUserId)
                          }
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match = state.status;
                  if (typeof match !== "object" && match === "FetchingUsers") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingUsers",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              rolesFilter: state.rolesFilter,
                              selection: ID.$$Set.make(),
                              editUser: state.editUser,
                              entityApproveUpdates: state.entityApproveUpdates,
                              entityApiEnableUpdates: state.entityApiEnableUpdates,
                              entityRoleUpdates: state.entityRoleUpdates,
                              entityMarketplaceLeadsUpdates: state.entityMarketplaceLeadsUpdates,
                              entityDailyLeadsUpdates: state.entityDailyLeadsUpdates,
                              entitySignupDistributionUpdates: state.entitySignupDistributionUpdates,
                              isEditModalOpen: state.isEditModalOpen,
                              users: state.users,
                              totalUsers: state.totalUsers,
                              currentUserId: state.currentUserId
                            },
                            _1: (function (param) {
                                param.dispatch("FetchUsers");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "SortBy" :
                  var sort = action._0;
                  var match$1 = state.status;
                  if (typeof match$1 !== "object" && match$1 === "FetchingUsers") {
                    return "NoUpdate";
                  }
                  var tmp;
                  if (sort === "Approved") {
                    var match$2 = state.sortBy;
                    tmp = typeof match$2 === "object" && match$2.NAME === "Approved" && match$2.VAL === "Desc" ? ({
                          NAME: "Approved",
                          VAL: "Asc"
                        }) : ({
                          NAME: "Approved",
                          VAL: "Desc"
                        });
                  } else if (sort === "Email") {
                    var match$3 = state.sortBy;
                    tmp = typeof match$3 === "object" && match$3.NAME === "Email" && match$3.VAL === "Desc" ? ({
                          NAME: "Email",
                          VAL: "Asc"
                        }) : ({
                          NAME: "Email",
                          VAL: "Desc"
                        });
                  } else if (sort === "FirstName") {
                    var match$4 = state.sortBy;
                    tmp = typeof match$4 === "object" && match$4.NAME === "FirstName" && match$4.VAL === "Desc" ? ({
                          NAME: "FirstName",
                          VAL: "Asc"
                        }) : ({
                          NAME: "FirstName",
                          VAL: "Desc"
                        });
                  } else if (sort === "LastName") {
                    var match$5 = state.sortBy;
                    tmp = typeof match$5 === "object" && match$5.NAME === "LastName" && match$5.VAL === "Desc" ? ({
                          NAME: "LastName",
                          VAL: "Asc"
                        }) : ({
                          NAME: "LastName",
                          VAL: "Desc"
                        });
                  } else if (sort === "Role") {
                    var match$6 = state.sortBy;
                    tmp = typeof match$6 === "object" && match$6.NAME === "Role" && match$6.VAL === "Desc" ? ({
                          NAME: "Role",
                          VAL: "Asc"
                        }) : ({
                          NAME: "Role",
                          VAL: "Desc"
                        });
                  } else {
                    var match$7 = state.sortBy;
                    tmp = typeof match$7 === "object" && match$7.NAME === "ApiEnabled" && match$7.VAL === "Desc" ? ({
                          NAME: "ApiEnabled",
                          VAL: "Asc"
                        }) : ({
                          NAME: "ApiEnabled",
                          VAL: "Desc"
                        });
                  }
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingUsers",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: tmp,
                            search: state.search,
                            rolesFilter: state.rolesFilter,
                            selection: ID.$$Set.make(),
                            editUser: state.editUser,
                            entityApproveUpdates: state.entityApproveUpdates,
                            entityApiEnableUpdates: state.entityApiEnableUpdates,
                            entityRoleUpdates: state.entityRoleUpdates,
                            entityMarketplaceLeadsUpdates: state.entityMarketplaceLeadsUpdates,
                            entityDailyLeadsUpdates: state.entityDailyLeadsUpdates,
                            entitySignupDistributionUpdates: state.entitySignupDistributionUpdates,
                            isEditModalOpen: state.isEditModalOpen,
                            users: state.users,
                            totalUsers: state.totalUsers,
                            currentUserId: state.currentUserId
                          },
                          _1: (function (param) {
                              param.dispatch("FetchUsers");
                            })
                        };
              case "ToggleRolesFilter" :
                  var role = action._0;
                  var match$8 = state.status;
                  if (typeof match$8 !== "object" && match$8 === "FetchingUsers") {
                    return "NoUpdate";
                  }
                  var notRole = function (r) {
                    return r !== role;
                  };
                  var rolesFilter = state.rolesFilter.includes(role) ? (function (__x) {
                          return __x.filter(notRole);
                        })(state.rolesFilter) : state.rolesFilter.concat([role]);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingUsers",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            rolesFilter: rolesFilter,
                            selection: ID.$$Set.make(),
                            editUser: state.editUser,
                            entityApproveUpdates: state.entityApproveUpdates,
                            entityApiEnableUpdates: state.entityApiEnableUpdates,
                            entityRoleUpdates: state.entityRoleUpdates,
                            entityMarketplaceLeadsUpdates: state.entityMarketplaceLeadsUpdates,
                            entityDailyLeadsUpdates: state.entityDailyLeadsUpdates,
                            entitySignupDistributionUpdates: state.entitySignupDistributionUpdates,
                            isEditModalOpen: state.isEditModalOpen,
                            users: state.users,
                            totalUsers: state.totalUsers,
                            currentUserId: state.currentUserId
                          },
                          _1: (function (param) {
                              param.dispatch("FetchUsers");
                            })
                        };
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: action._0,
                            rolesFilter: state.rolesFilter,
                            selection: state.selection,
                            editUser: state.editUser,
                            entityApproveUpdates: state.entityApproveUpdates,
                            entityApiEnableUpdates: state.entityApiEnableUpdates,
                            entityRoleUpdates: state.entityRoleUpdates,
                            entityMarketplaceLeadsUpdates: state.entityMarketplaceLeadsUpdates,
                            entityDailyLeadsUpdates: state.entityDailyLeadsUpdates,
                            entitySignupDistributionUpdates: state.entitySignupDistributionUpdates,
                            isEditModalOpen: state.isEditModalOpen,
                            users: state.users,
                            totalUsers: state.totalUsers,
                            currentUserId: state.currentUserId
                          },
                          _1: searchUsers
                        };
              case "PerformSearch" :
                  var match$9 = state.status;
                  if (typeof match$9 !== "object" && match$9 === "FetchingUsers") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.search) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingUsers",
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              rolesFilter: state.rolesFilter,
                              selection: ID.$$Set.make(),
                              editUser: state.editUser,
                              entityApproveUpdates: state.entityApproveUpdates,
                              entityApiEnableUpdates: state.entityApiEnableUpdates,
                              entityRoleUpdates: state.entityRoleUpdates,
                              entityMarketplaceLeadsUpdates: state.entityMarketplaceLeadsUpdates,
                              entityDailyLeadsUpdates: state.entityDailyLeadsUpdates,
                              entitySignupDistributionUpdates: state.entitySignupDistributionUpdates,
                              isEditModalOpen: state.isEditModalOpen,
                              users: state.users,
                              totalUsers: state.totalUsers,
                              currentUserId: state.currentUserId
                            },
                            _1: (function (param) {
                                param.dispatch("FetchUsers");
                              })
                          };
                  }
              case "UpdateApproved" :
                  var action$1 = action._1;
                  var userId = action._0;
                  var match$10 = state.status;
                  var match$11 = Belt_Map.get(state.entityApproveUpdates, userId);
                  if (typeof match$10 !== "object" || match$11 !== undefined) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(match$10._0, (function (x) {
                                        if (!Caml_obj.equal(x.id, userId)) {
                                          return x;
                                        }
                                        var tmp;
                                        tmp = action$1 === "Approved" ? true : false;
                                        return {
                                                id: x.id,
                                                firstName: x.firstName,
                                                lastName: x.lastName,
                                                email: x.email,
                                                role: x.role,
                                                approved: tmp,
                                                apiEnabled: x.apiEnabled,
                                                signupDistribution: x.signupDistribution,
                                                dailyLeads: x.dailyLeads
                                              };
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              rolesFilter: state.rolesFilter,
                              selection: state.selection,
                              editUser: state.editUser,
                              entityApproveUpdates: Belt_Map.set(state.entityApproveUpdates, userId, {
                                    TAG: "ApproveUpdate",
                                    _0: action$1
                                  }),
                              entityApiEnableUpdates: state.entityApiEnableUpdates,
                              entityRoleUpdates: state.entityRoleUpdates,
                              entityMarketplaceLeadsUpdates: state.entityMarketplaceLeadsUpdates,
                              entityDailyLeadsUpdates: state.entityDailyLeadsUpdates,
                              entitySignupDistributionUpdates: state.entitySignupDistributionUpdates,
                              isEditModalOpen: state.isEditModalOpen,
                              users: state.users,
                              totalUsers: state.totalUsers,
                              currentUserId: state.currentUserId
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                if (action$1 === "Approved") {
                                  return $$Promise.wait(Api_User.updateApprovedDashboard(userId, "Approved"), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "SucceedApproveUpdate",
                                                              _0: userId
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "UsersDashboard",
                                                      subModulePath: /* [] */0,
                                                      value: "default",
                                                      fullPath: "UsersDashboard.default"
                                                    }, "UsersDashboard" + "::UpdateStatus::Approve::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailApproveUpdate",
                                                      _0: userId
                                                    });
                                              }));
                                } else {
                                  return $$Promise.wait(Api_User.updateApprovedDashboard(userId, "Unapproved"), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "SucceedApproveUpdate",
                                                              _0: userId
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "UsersDashboard",
                                                      subModulePath: /* [] */0,
                                                      value: "default",
                                                      fullPath: "UsersDashboard.default"
                                                    }, "UsersDashboard" + "::UpdateStatus::Unapprove::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailApproveUpdate",
                                                      _0: userId
                                                    });
                                              }));
                                }
                              })
                          };
                  }
              case "SucceedApproveUpdate" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            rolesFilter: state.rolesFilter,
                            selection: state.selection,
                            editUser: state.editUser,
                            entityApproveUpdates: Belt_Map.remove(state.entityApproveUpdates, action._0),
                            entityApiEnableUpdates: state.entityApiEnableUpdates,
                            entityRoleUpdates: state.entityRoleUpdates,
                            entityMarketplaceLeadsUpdates: state.entityMarketplaceLeadsUpdates,
                            entityDailyLeadsUpdates: state.entityDailyLeadsUpdates,
                            entitySignupDistributionUpdates: state.entitySignupDistributionUpdates,
                            isEditModalOpen: state.isEditModalOpen,
                            users: state.users,
                            totalUsers: state.totalUsers,
                            currentUserId: state.currentUserId
                          }
                        };
              case "FailApproveUpdate" :
                  var userId$1 = action._0;
                  var match$12 = state.status;
                  var match$13 = Belt_Map.get(state.entityApproveUpdates, userId$1);
                  if (typeof match$12 !== "object") {
                    return "NoUpdate";
                  }
                  if (match$13 === undefined) {
                    return "NoUpdate";
                  }
                  var action$2 = match$13._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: Belt_Array.map(match$12._0, (function (x) {
                                      if (!Caml_obj.equal(x.id, userId$1)) {
                                        return x;
                                      }
                                      var tmp;
                                      tmp = action$2 === "Approved" ? true : false;
                                      return {
                                              id: x.id,
                                              firstName: x.firstName,
                                              lastName: x.lastName,
                                              email: x.email,
                                              role: x.role,
                                              approved: tmp,
                                              apiEnabled: x.apiEnabled,
                                              signupDistribution: x.signupDistribution,
                                              dailyLeads: x.dailyLeads
                                            };
                                    }))
                            },
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            rolesFilter: state.rolesFilter,
                            selection: state.selection,
                            editUser: state.editUser,
                            entityApproveUpdates: Belt_Map.remove(state.entityApproveUpdates, userId$1),
                            entityApiEnableUpdates: state.entityApiEnableUpdates,
                            entityRoleUpdates: state.entityRoleUpdates,
                            entityMarketplaceLeadsUpdates: state.entityMarketplaceLeadsUpdates,
                            entityDailyLeadsUpdates: state.entityDailyLeadsUpdates,
                            entitySignupDistributionUpdates: state.entitySignupDistributionUpdates,
                            isEditModalOpen: state.isEditModalOpen,
                            users: state.users,
                            totalUsers: state.totalUsers,
                            currentUserId: state.currentUserId
                          }
                        };
              case "UpdateApiEnabled" :
                  var action$3 = action._1;
                  var userId$2 = action._0;
                  var match$14 = state.status;
                  var match$15 = Belt_Map.get(state.entityApiEnableUpdates, userId$2);
                  if (typeof match$14 !== "object" || match$15 !== undefined) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(match$14._0, (function (x) {
                                        if (!Caml_obj.equal(x.id, userId$2)) {
                                          return x;
                                        }
                                        var tmp;
                                        tmp = action$3 === "Enabled" ? true : false;
                                        return {
                                                id: x.id,
                                                firstName: x.firstName,
                                                lastName: x.lastName,
                                                email: x.email,
                                                role: x.role,
                                                approved: x.approved,
                                                apiEnabled: tmp,
                                                signupDistribution: x.signupDistribution,
                                                dailyLeads: x.dailyLeads
                                              };
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              rolesFilter: state.rolesFilter,
                              selection: state.selection,
                              editUser: state.editUser,
                              entityApproveUpdates: state.entityApproveUpdates,
                              entityApiEnableUpdates: Belt_Map.set(state.entityApiEnableUpdates, userId$2, {
                                    TAG: "ApiEnableUpdate",
                                    _0: action$3
                                  }),
                              entityRoleUpdates: state.entityRoleUpdates,
                              entityMarketplaceLeadsUpdates: state.entityMarketplaceLeadsUpdates,
                              entityDailyLeadsUpdates: state.entityDailyLeadsUpdates,
                              entitySignupDistributionUpdates: state.entitySignupDistributionUpdates,
                              isEditModalOpen: state.isEditModalOpen,
                              users: state.users,
                              totalUsers: state.totalUsers,
                              currentUserId: state.currentUserId
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                if (action$3 === "Enabled") {
                                  return $$Promise.wait(Api_User.updateApiEnabledDashboard(userId$2, "Enabled"), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "SucceedApiEnableUpdate",
                                                              _0: userId$2
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "UsersDashboard",
                                                      subModulePath: /* [] */0,
                                                      value: "default",
                                                      fullPath: "UsersDashboard.default"
                                                    }, "UsersDashboard" + "::UpdateStatus::Enable::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailApiEnableUpdate",
                                                      _0: userId$2
                                                    });
                                              }));
                                } else {
                                  return $$Promise.wait(Api_User.updateApiEnabledDashboard(userId$2, "Disabled"), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "SucceedApiEnableUpdate",
                                                              _0: userId$2
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "UsersDashboard",
                                                      subModulePath: /* [] */0,
                                                      value: "default",
                                                      fullPath: "UsersDashboard.default"
                                                    }, "UsersDashboard" + "::UpdateStatus::Disable::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailApiEnableUpdate",
                                                      _0: userId$2
                                                    });
                                              }));
                                }
                              })
                          };
                  }
              case "SucceedApiEnableUpdate" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            rolesFilter: state.rolesFilter,
                            selection: state.selection,
                            editUser: state.editUser,
                            entityApproveUpdates: state.entityApproveUpdates,
                            entityApiEnableUpdates: Belt_Map.remove(state.entityApiEnableUpdates, action._0),
                            entityRoleUpdates: state.entityRoleUpdates,
                            entityMarketplaceLeadsUpdates: state.entityMarketplaceLeadsUpdates,
                            entityDailyLeadsUpdates: state.entityDailyLeadsUpdates,
                            entitySignupDistributionUpdates: state.entitySignupDistributionUpdates,
                            isEditModalOpen: state.isEditModalOpen,
                            users: state.users,
                            totalUsers: state.totalUsers,
                            currentUserId: state.currentUserId
                          }
                        };
              case "FailApiEnableUpdate" :
                  var userId$3 = action._0;
                  var match$16 = state.status;
                  var match$17 = Belt_Map.get(state.entityApiEnableUpdates, userId$3);
                  if (typeof match$16 !== "object") {
                    return "NoUpdate";
                  }
                  if (match$17 === undefined) {
                    return "NoUpdate";
                  }
                  var action$4 = match$17._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: Belt_Array.map(match$16._0, (function (x) {
                                      if (!Caml_obj.equal(x.id, userId$3)) {
                                        return x;
                                      }
                                      var tmp;
                                      tmp = action$4 === "Enabled" ? true : false;
                                      return {
                                              id: x.id,
                                              firstName: x.firstName,
                                              lastName: x.lastName,
                                              email: x.email,
                                              role: x.role,
                                              approved: x.approved,
                                              apiEnabled: tmp,
                                              signupDistribution: x.signupDistribution,
                                              dailyLeads: x.dailyLeads
                                            };
                                    }))
                            },
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            rolesFilter: state.rolesFilter,
                            selection: state.selection,
                            editUser: state.editUser,
                            entityApproveUpdates: state.entityApproveUpdates,
                            entityApiEnableUpdates: Belt_Map.remove(state.entityApiEnableUpdates, userId$3),
                            entityRoleUpdates: state.entityRoleUpdates,
                            entityMarketplaceLeadsUpdates: state.entityMarketplaceLeadsUpdates,
                            entityDailyLeadsUpdates: state.entityDailyLeadsUpdates,
                            entitySignupDistributionUpdates: state.entitySignupDistributionUpdates,
                            isEditModalOpen: state.isEditModalOpen,
                            users: state.users,
                            totalUsers: state.totalUsers,
                            currentUserId: state.currentUserId
                          }
                        };
              case "SucceedFetchUser" :
              case "UpdateUser" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            rolesFilter: state.rolesFilter,
                            selection: state.selection,
                            editUser: action._0,
                            entityApproveUpdates: state.entityApproveUpdates,
                            entityApiEnableUpdates: state.entityApiEnableUpdates,
                            entityRoleUpdates: state.entityRoleUpdates,
                            entityMarketplaceLeadsUpdates: state.entityMarketplaceLeadsUpdates,
                            entityDailyLeadsUpdates: state.entityDailyLeadsUpdates,
                            entitySignupDistributionUpdates: state.entitySignupDistributionUpdates,
                            isEditModalOpen: state.isEditModalOpen,
                            users: state.users,
                            totalUsers: state.totalUsers,
                            currentUserId: state.currentUserId
                          }
                        };
              case "UpdateDailyLeads" :
                  var action$5 = action._1;
                  var userId$4 = action._0;
                  var match$18 = state.status;
                  var match$19 = Belt_Map.get(state.entityDailyLeadsUpdates, userId$4);
                  if (typeof match$18 !== "object" || match$19 !== undefined) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(match$18._0, (function (x) {
                                        if (!Caml_obj.equal(x.id, userId$4)) {
                                          return x;
                                        }
                                        var tmp;
                                        tmp = action$5 === "Enabled" ? true : false;
                                        return {
                                                id: x.id,
                                                firstName: x.firstName,
                                                lastName: x.lastName,
                                                email: x.email,
                                                role: x.role,
                                                approved: x.approved,
                                                apiEnabled: x.apiEnabled,
                                                signupDistribution: x.signupDistribution,
                                                dailyLeads: tmp
                                              };
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              rolesFilter: state.rolesFilter,
                              selection: state.selection,
                              editUser: state.editUser,
                              entityApproveUpdates: state.entityApproveUpdates,
                              entityApiEnableUpdates: state.entityApiEnableUpdates,
                              entityRoleUpdates: state.entityRoleUpdates,
                              entityMarketplaceLeadsUpdates: state.entityMarketplaceLeadsUpdates,
                              entityDailyLeadsUpdates: Belt_Map.set(state.entityDailyLeadsUpdates, userId$4, {
                                    TAG: "DailyLeadsUpdate",
                                    _0: action$5
                                  }),
                              entitySignupDistributionUpdates: state.entitySignupDistributionUpdates,
                              isEditModalOpen: state.isEditModalOpen,
                              users: state.users,
                              totalUsers: state.totalUsers,
                              currentUserId: state.currentUserId
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                if (action$5 === "Enabled") {
                                  return $$Promise.wait(Api_User.updateDailyLeadsDashboard(userId$4, "Enabled"), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  dispatch({
                                                        TAG: "SucceedDailyLeadsUpdate",
                                                        _0: userId$4
                                                      });
                                                  return dispatch({
                                                              TAG: "SucceedFetchUser",
                                                              _0: x._0
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "UsersDashboard",
                                                      subModulePath: /* [] */0,
                                                      value: "default",
                                                      fullPath: "UsersDashboard.default"
                                                    }, "UsersDashboard" + "::UpdateStatus::Enable::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailDailyLeadsUpdate",
                                                      _0: userId$4
                                                    });
                                              }));
                                } else {
                                  return $$Promise.wait(Api_User.updateDailyLeadsDashboard(userId$4, "Disabled"), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  dispatch({
                                                        TAG: "SucceedDailyLeadsUpdate",
                                                        _0: userId$4
                                                      });
                                                  return dispatch({
                                                              TAG: "SucceedFetchUser",
                                                              _0: x._0
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "UsersDashboard",
                                                      subModulePath: /* [] */0,
                                                      value: "default",
                                                      fullPath: "UsersDashboard.default"
                                                    }, "UsersDashboard" + "::UpdateStatus::Disable::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailDailyLeadsUpdate",
                                                      _0: userId$4
                                                    });
                                              }));
                                }
                              })
                          };
                  }
              case "SucceedDailyLeadsUpdate" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            rolesFilter: state.rolesFilter,
                            selection: state.selection,
                            editUser: state.editUser,
                            entityApproveUpdates: state.entityApproveUpdates,
                            entityApiEnableUpdates: state.entityApiEnableUpdates,
                            entityRoleUpdates: state.entityRoleUpdates,
                            entityMarketplaceLeadsUpdates: state.entityMarketplaceLeadsUpdates,
                            entityDailyLeadsUpdates: Belt_Map.remove(state.entityDailyLeadsUpdates, action._0),
                            entitySignupDistributionUpdates: state.entitySignupDistributionUpdates,
                            isEditModalOpen: state.isEditModalOpen,
                            users: state.users,
                            totalUsers: state.totalUsers,
                            currentUserId: state.currentUserId
                          }
                        };
              case "FailDailyLeadsUpdate" :
                  var userId$5 = action._0;
                  var match$20 = state.status;
                  var match$21 = Belt_Map.get(state.entityDailyLeadsUpdates, userId$5);
                  if (typeof match$20 !== "object" || match$21 === undefined) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(match$20._0, (function (x) {
                                        if (Caml_obj.equal(x.id, userId$5)) {
                                          return {
                                                  id: x.id,
                                                  firstName: x.firstName,
                                                  lastName: x.lastName,
                                                  email: x.email,
                                                  role: x.role,
                                                  approved: x.approved,
                                                  apiEnabled: x.apiEnabled,
                                                  signupDistribution: x.signupDistribution,
                                                  dailyLeads: x.dailyLeads
                                                };
                                        } else {
                                          return x;
                                        }
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              rolesFilter: state.rolesFilter,
                              selection: state.selection,
                              editUser: state.editUser,
                              entityApproveUpdates: state.entityApproveUpdates,
                              entityApiEnableUpdates: state.entityApiEnableUpdates,
                              entityRoleUpdates: state.entityRoleUpdates,
                              entityMarketplaceLeadsUpdates: state.entityMarketplaceLeadsUpdates,
                              entityDailyLeadsUpdates: Belt_Map.remove(state.entityDailyLeadsUpdates, userId$5),
                              entitySignupDistributionUpdates: state.entitySignupDistributionUpdates,
                              isEditModalOpen: state.isEditModalOpen,
                              users: state.users,
                              totalUsers: state.totalUsers,
                              currentUserId: state.currentUserId
                            }
                          };
                  }
              case "UpdateSignupDistribution" :
                  var action$6 = action._1;
                  var userId$6 = action._0;
                  var match$22 = state.status;
                  var match$23 = Belt_Map.get(state.entitySignupDistributionUpdates, userId$6);
                  if (typeof match$22 !== "object" || match$23 !== undefined) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(match$22._0, (function (x) {
                                        if (!Caml_obj.equal(x.id, userId$6)) {
                                          return x;
                                        }
                                        var tmp;
                                        tmp = action$6 === "Enabled" ? true : false;
                                        return {
                                                id: x.id,
                                                firstName: x.firstName,
                                                lastName: x.lastName,
                                                email: x.email,
                                                role: x.role,
                                                approved: x.approved,
                                                apiEnabled: x.apiEnabled,
                                                signupDistribution: tmp,
                                                dailyLeads: x.dailyLeads
                                              };
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              rolesFilter: state.rolesFilter,
                              selection: state.selection,
                              editUser: state.editUser,
                              entityApproveUpdates: state.entityApproveUpdates,
                              entityApiEnableUpdates: state.entityApiEnableUpdates,
                              entityRoleUpdates: state.entityRoleUpdates,
                              entityMarketplaceLeadsUpdates: state.entityMarketplaceLeadsUpdates,
                              entityDailyLeadsUpdates: state.entityDailyLeadsUpdates,
                              entitySignupDistributionUpdates: Belt_Map.set(state.entitySignupDistributionUpdates, userId$6, {
                                    TAG: "SignupDistributionUpdate",
                                    _0: action$6
                                  }),
                              isEditModalOpen: state.isEditModalOpen,
                              users: state.users,
                              totalUsers: state.totalUsers,
                              currentUserId: state.currentUserId
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                if (action$6 === "Enabled") {
                                  return $$Promise.wait(Api_User.updateSignupDistributionDashboard(userId$6, "Enabled"), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  dispatch({
                                                        TAG: "SucceedSignupDistributionUpdate",
                                                        _0: userId$6
                                                      });
                                                  return dispatch({
                                                              TAG: "SucceedFetchUser",
                                                              _0: x._0
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "UsersDashboard",
                                                      subModulePath: /* [] */0,
                                                      value: "default",
                                                      fullPath: "UsersDashboard.default"
                                                    }, "UsersDashboard" + "::UpdateStatus::Enable::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailSignupDistributionUpdate",
                                                      _0: userId$6
                                                    });
                                              }));
                                } else {
                                  return $$Promise.wait(Api_User.updateSignupDistributionDashboard(userId$6, "Disabled"), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  dispatch({
                                                        TAG: "SucceedSignupDistributionUpdate",
                                                        _0: userId$6
                                                      });
                                                  return dispatch({
                                                              TAG: "SucceedFetchUser",
                                                              _0: x._0
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "UsersDashboard",
                                                      subModulePath: /* [] */0,
                                                      value: "default",
                                                      fullPath: "UsersDashboard.default"
                                                    }, "UsersDashboard" + "::UpdateStatus::Disable::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailSignupDistributionUpdate",
                                                      _0: userId$6
                                                    });
                                              }));
                                }
                              })
                          };
                  }
              case "SucceedSignupDistributionUpdate" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            rolesFilter: state.rolesFilter,
                            selection: state.selection,
                            editUser: state.editUser,
                            entityApproveUpdates: state.entityApproveUpdates,
                            entityApiEnableUpdates: state.entityApiEnableUpdates,
                            entityRoleUpdates: state.entityRoleUpdates,
                            entityMarketplaceLeadsUpdates: state.entityMarketplaceLeadsUpdates,
                            entityDailyLeadsUpdates: state.entityDailyLeadsUpdates,
                            entitySignupDistributionUpdates: Belt_Map.remove(state.entitySignupDistributionUpdates, action._0),
                            isEditModalOpen: state.isEditModalOpen,
                            users: state.users,
                            totalUsers: state.totalUsers,
                            currentUserId: state.currentUserId
                          }
                        };
              case "FailSignupDistributionUpdate" :
                  var userId$7 = action._0;
                  var match$24 = state.status;
                  var match$25 = Belt_Map.get(state.entitySignupDistributionUpdates, userId$7);
                  if (typeof match$24 !== "object") {
                    return "NoUpdate";
                  }
                  if (match$25 === undefined) {
                    return "NoUpdate";
                  }
                  var action$7 = match$25._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: Belt_Array.map(match$24._0, (function (x) {
                                      if (!Caml_obj.equal(x.id, userId$7)) {
                                        return x;
                                      }
                                      var tmp;
                                      tmp = action$7 === "Enabled" ? true : false;
                                      return {
                                              id: x.id,
                                              firstName: x.firstName,
                                              lastName: x.lastName,
                                              email: x.email,
                                              role: x.role,
                                              approved: x.approved,
                                              apiEnabled: x.apiEnabled,
                                              signupDistribution: tmp,
                                              dailyLeads: x.dailyLeads
                                            };
                                    }))
                            },
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            rolesFilter: state.rolesFilter,
                            selection: state.selection,
                            editUser: state.editUser,
                            entityApproveUpdates: state.entityApproveUpdates,
                            entityApiEnableUpdates: state.entityApiEnableUpdates,
                            entityRoleUpdates: state.entityRoleUpdates,
                            entityMarketplaceLeadsUpdates: state.entityMarketplaceLeadsUpdates,
                            entityDailyLeadsUpdates: state.entityDailyLeadsUpdates,
                            entitySignupDistributionUpdates: Belt_Map.remove(state.entitySignupDistributionUpdates, userId$7),
                            isEditModalOpen: state.isEditModalOpen,
                            users: state.users,
                            totalUsers: state.totalUsers,
                            currentUserId: state.currentUserId
                          }
                        };
              case "OpenModal" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            rolesFilter: state.rolesFilter,
                            selection: state.selection,
                            editUser: action._0,
                            entityApproveUpdates: state.entityApproveUpdates,
                            entityApiEnableUpdates: state.entityApiEnableUpdates,
                            entityRoleUpdates: state.entityRoleUpdates,
                            entityMarketplaceLeadsUpdates: state.entityMarketplaceLeadsUpdates,
                            entityDailyLeadsUpdates: state.entityDailyLeadsUpdates,
                            entitySignupDistributionUpdates: state.entitySignupDistributionUpdates,
                            isEditModalOpen: true,
                            users: state.users,
                            totalUsers: state.totalUsers,
                            currentUserId: state.currentUserId
                          }
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchUsers");
        }), []);
  var len = Role.filterFields.length;
  var tmp;
  if (len !== 1 && len !== 0) {
    var x = state.rolesFilter.length;
    tmp = JsxRuntime.jsxs(Dropdown.make, {
          children: [
            JsxRuntime.jsx(Dropdown.Trigger.make, {
                  className: css.dropdown,
                  children: x !== 0 ? (
                      x !== 1 ? String(x) + " Roles" : "1 Role"
                    ) : "All Roles"
                }),
            JsxRuntime.jsx(Dropdown.Body.make, {
                  position: {
                    TAG: "Below",
                    _0: "LeftEdge"
                  },
                  className: css.dropdownBody,
                  children: Belt_Array.map(Belt_Array.slice(Role.filterFields, 0, 5), (function (role) {
                          var id = "users-roles-filter--roles-" + Role.toString(role);
                          return JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Checkbox.make, {
                                              id: id,
                                              size: "SM",
                                              checked: state.rolesFilter.includes(role),
                                              onChange: (function (param) {
                                                  dispatch({
                                                        TAG: "ToggleRolesFilter",
                                                        _0: role
                                                      });
                                                })
                                            }),
                                        JsxRuntime.jsx("label", {
                                              children: Role.toCapital(role),
                                              className: css.label,
                                              htmlFor: id
                                            })
                                      ],
                                      className: css.dropdownBodyRow
                                    }, id);
                        }))
                })
          ]
        });
  } else {
    tmp = null;
  }
  var match$1 = state.sortBy;
  var match$2 = state.sortBy;
  var match$3 = state.sortBy;
  var match$4 = state.sortBy;
  var match$5 = state.sortBy;
  var match$6 = state.sortBy;
  var users = state.status;
  var tmp$1;
  if (typeof users !== "object") {
    tmp$1 = users === "FetchingUsers" ? JsxRuntime.jsx(TableRow.make, {
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Spinner.make, {
                              size: "XL",
                              color: "Teal"
                            }),
                        className: css.spinner
                      })
                })
          }) : JsxRuntime.jsx(TableRow.make, {
            className: css.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "Something went wrong during the search"
                })
          });
  } else {
    var users$1 = users._0;
    tmp$1 = users$1.length !== 0 ? Belt_Array.mapWithIndex(users$1, (function (index, user) {
              var approved = user.approved;
              var apiEnabled = user.apiEnabled;
              return JsxRuntime.jsxs(TableRow.make, {
                          children: [
                            JsxRuntime.jsx(TableCell.make, {
                                  children: user.firstName
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: user.lastName
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: user.email
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: $$String.capitalize_ascii(user.role)
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx(Toggle.make, {
                                        on: approved,
                                        size: "MD",
                                        onChange: (function () {
                                            dispatch({
                                                  TAG: "UpdateApproved",
                                                  _0: user.id,
                                                  _1: approved ? "Unapproved" : "Approved"
                                                });
                                          })
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx(Toggle.make, {
                                        on: apiEnabled,
                                        size: "MD",
                                        onChange: (function () {
                                            dispatch({
                                                  TAG: "UpdateApiEnabled",
                                                  _0: user.id,
                                                  _1: apiEnabled ? "Disabled" : "Enabled"
                                                });
                                          })
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(Control.make, {
                                              onClick: (function (param) {
                                                  dispatch({
                                                        TAG: "OpenModal",
                                                        _0: user
                                                      });
                                                }),
                                              children: JsxRuntime.jsx(IconMenu.make, {
                                                    size: "MD",
                                                    color: "Teal"
                                                  })
                                            }),
                                        className: css.blogPostMenu
                                      })
                                })
                          ]
                        }, String(index));
            })) : JsxRuntime.jsx(TableRow.make, {
            className: css.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "No users were found."
                })
          });
  }
  var match$7 = state.isEditModalOpen;
  var match$8 = state.editUser;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(UsersDashboardNavbar.make, {
                      selectedCategory: "Users"
                    }),
                JsxRuntime.jsxs(Container.make, {
                      className: css.layout,
                      setRef: Caml_option.some(container),
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(H1.make, {
                                      className: css.title,
                                      children: "Users Dashboard"
                                    }),
                                JsxRuntime.jsx(H1.make, {
                                      className: css.totalUsers,
                                      children: "(" + String(state.totalUsers) + ")"
                                    })
                              ],
                              className: css.header
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(SearchField.make, {
                                      id: "users-search",
                                      value: state.search,
                                      placeholder: "Search by name or email",
                                      inputClassName: css.searchField,
                                      onChange: (function ($$event) {
                                          dispatch({
                                                TAG: "UpdateSearchInput",
                                                _0: $$event.target.value
                                              });
                                        })
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: tmp,
                                      className: css.headerControls
                                    })
                              ],
                              className: css.subHeader
                            }),
                        JsxRuntime.jsxs(Table.make, {
                              className: css.table,
                              children: [
                                JsxRuntime.jsx(TableHeader.make, {
                                      children: JsxRuntime.jsxs(TableRow.make, {
                                            children: [
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "FirstName"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "First Name",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$1 === "object" && match$1.NAME === "FirstName" ? match$1.VAL : undefined
                                                                      })
                                                                ],
                                                                className: css.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "LastName"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Last Name",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$2 === "object" && match$2.NAME === "LastName" ? match$2.VAL : undefined
                                                                      })
                                                                ],
                                                                className: css.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "Email"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Email",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$3 === "object" && match$3.NAME === "Email" ? match$3.VAL : undefined
                                                                      })
                                                                ],
                                                                className: css.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "Role"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Role",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$4 === "object" && match$4.NAME === "Role" ? match$4.VAL : undefined
                                                                      })
                                                                ],
                                                                className: css.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "Approved"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Approved",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$5 === "object" && match$5.NAME === "Approved" ? match$5.VAL : undefined
                                                                      })
                                                                ],
                                                                className: css.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "ApiEnabled"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "API Enabled",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$6 === "object" && match$6.NAME === "ApiEnabled" ? match$6.VAL : undefined
                                                                      })
                                                                ],
                                                                className: css.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: ""
                                                  })
                                            ]
                                          })
                                    }),
                                JsxRuntime.jsx(TableBody.make, {
                                      children: tmp$1
                                    })
                              ]
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Pagination.make, {
                                    currentPage: state.currentPage,
                                    totalPages: state.totalPages,
                                    onPageClick: (function (x) {
                                        dispatch({
                                              TAG: "UpdatePage",
                                              _0: x
                                            });
                                      })
                                  }),
                              className: css.pagination
                            })
                      ]
                    }),
                match$7 && match$8 !== undefined ? JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx("div", {
                                className: css.modalOverlay,
                                onClick: (function (param) {
                                    dispatch("CloseModal");
                                  })
                              }),
                          JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Control.make, {
                                              className: css.closeOverlayButton,
                                              onClick: (function (param) {
                                                  dispatch("CloseModal");
                                                }),
                                              children: JsxRuntime.jsx(IconClose.make, {
                                                    title: "Close",
                                                    size: "MD",
                                                    color: "Gray"
                                                  })
                                            }),
                                        JsxRuntime.jsx("h2", {
                                              children: "Edit User: " + match$8.firstName + " " + match$8.lastName
                                            }),
                                        JsxRuntime.jsx("h3", {
                                              children: "Edit the user's role and lead permissions."
                                            }),
                                        JsxRuntime.jsx(UserEditForm.make, {
                                              user: match$8,
                                              users: {
                                                users: Belt_Option.getExn(state.users),
                                                currentPage: state.currentPage,
                                                totalPages: state.totalPages
                                              },
                                              updateUsers: (function (param) {
                                                  dispatch("FetchUsers");
                                                }),
                                              currentUserId: Belt_Option.getExn(state.currentUserId)
                                            }),
                                        JsxRuntime.jsx("a", {
                                              children: "Edit Profile",
                                              href: Routes_User.Dashboard.edit(match$8.id)
                                            })
                                      ],
                                      className: css.modalContents
                                    }),
                                className: css.sideModal,
                                id: "sideModal"
                              })
                        ],
                        className: css.sideModalContainer,
                        id: "userModal"
                      }) : null
              ],
              className: css.pageContainer
            });
}

var $$default = UsersDashboard$default;

export {
  css ,
  initialState ,
  $$default as default,
}
/* css Not a pure module */
